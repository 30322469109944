
import {Component, Mixins, Prop} from 'vue-property-decorator'
import BaseResourceFormDialog from "@/components/base/BaseResourceFormDialog.vue"
import {ResourceFormDialogMixin} from "@/components/forms/FormsMixin"
import {SettingsResource, UserResource} from "@/resources"
import { BaseUserSettingsResourceComponent } from '../base/BaseMixins'
import UserSettingsForm from './UserSettingsForm.vue'

@Component({components: {BaseResourceFormDialog, UserSettingsForm}})
export default class UserSettingsDialog extends Mixins(ResourceFormDialogMixin, BaseUserSettingsResourceComponent) { 

  @Prop() readonly userResource!: UserResource

  newModelFunction() : any {
    return this.rdata
            ? this.rdata
            : {
              ...SettingsResource.defaultObject("userSetting")
            }
  }
  
  get user() {
    return this.userResource ? this.userResource.data() : ""
  }

}
