
import {Component, Prop} from 'vue-property-decorator' 
import ValueBasedFormsMixin from "@/components/forms/FormsMixin"
import BaseResourceSelector from '../base/BaseResourceSelector.vue'
import { EntityResource, UserResource } from '@/resources'

@Component({components: {BaseResourceSelector}})
export default class UserSettingsForm extends ValueBasedFormsMixin { 

  @Prop({required: true}) readonly userResource !: UserResource

  getHalFormName() : string { return "usersettings" }

  calendarLabelProvider(r : EntityResource) {
    const data = r.data()
    return data.primary ? "Default calendar (" + data.name + ")" : data.name
  }
}
