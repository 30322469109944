
import {Component, Vue} from 'vue-property-decorator'
import BasePanel from '@/components/base/BasePanel.vue'
import { BaseUserResourceComponent } from '@/components/base/BaseMixins'
import UserAvatar from '@/components/user/UserAvatar.vue'
import { ApiResource, SettingsResource, UserResource } from '@/resources'
import BaseOptionsMenu from '@/components/base/BaseOptionsMenu.vue'
import UserSettingsDialog from '@/components/user/UserSettingsDialog.vue'
import AsyncComputed from 'vue-async-computed-decorator'

@Component({metaInfo: {title:process.env.VUE_APP_NAME + " - User Administration"}, components : {BasePanel, UserSettingsDialog, BaseOptionsMenu, UserAvatar, BaseUserResourceComponent}})
export default class UsersView extends Vue {

  headers: any = [
    {
      text: "Photo",
      value: "photo",
      align: "start",
      sortable: false,
    },
    {
      text: "Name",
      align: "start",
      sortable: true,
      value: "name",
    },
    {
      text: "E-mail",
      align: "start",
      sortable: true,
      value: "email",
    }
  ]

  selectedUserResource : any = null

  get googleDomain() {
    return SettingsResource.DefaultsInstance.data().googleDomain
  }

  get googleDomainAdminEmail() {
    return SettingsResource.DefaultsInstance.data().googleDomainAdminEmail
  }

  get usersResource() {
    return ApiResource.Instance.users;
  }

  @AsyncComputed()
  async selectedUserSettingsResource() {
    if (!this.selectedUserResource) return

    await this.selectedUserResource.get()
    return this.selectedUserResource.settings.getAssociation()
  }

  editSettings(item : any) {
    this.selectedUserResource = new UserResource(item.id)
  }
  

}
